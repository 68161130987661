/* eslint-disable quotes */
import { EPCISEventType } from 'react-component-utils';

/**
 * Generates a credential payload for an EPCIS event link.
 * @param gtin The GTIN of the product.
 * @param batchId The batch ID of the product.
 * @param product map the gtin with mock data
 * @param batch map the batchId with mock data.
 * @param linkEpcis The link to the EPCIS event.
 * @returns A Promise that resolves to the credential payload for the EPCIS event link.
 * @throws An error if the credential is invalid or the API request fails.
 */
export const buildDPPCredentialSubject = (gtin: string, product: any, linkEpcis: string) => {
    return {
        product: {
            productId: gtin,
            productClass: product.productClass,
            weight: `${product.weight} grams`,
            image: product.image,
            description: product.description,
            manufacturer: {
                id: 'https://agtraceprocessors.com.au/',
                name: "Pete's Meats",
            },
        },
        batch: {
            batchId: '5698754215',
            manufacturedAt: {
                id: 'https://resolver.showthething.com/414/9377779541302',
                name: 'name',
                operatedBy: {
                    id: 'https://agtraceprocessors.com.au/',
                    name: 'AgTrace Processors',
                },
            },
            manufacturedDate: new Date().toDateString(),
            provenance: [
                {
                    country: 'AU',
                    percentage: 100,
                },
            ],
            sustainabilityInfo: [
                {
                    Topic: 'environment.deforestation',
                    Criteria: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32023R1115',
                    Evidence:
                        'https://web.agtrace.showthething.com/verify?q=%7B%22payload%22%3A%20%7B%22uri%22%3A%22https%3A%2F%2Fagtrace-verifiable-credentials.s3.ap-southeast-2.amazonaws.com%2Fprocessor-df-cert.json%22%7D%7D',
                    metric: [
                        {
                            Metric: 'Canopy cover reduction',
                            Unit: '% per km^2',
                            Value: 0,
                        },
                    ],
                    compliance: true,
                },
            ],
            traceabilityInfo: [
                {
                    EventReference: linkEpcis,
                    EventType: EPCISEventType.Transformation,
                },
            ],
        },
        sustainabilityScore: 65,
        trustScore: 48,
    };
};
