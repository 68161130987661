import {
    AgtraceLinkResolver,
    AgtraceLinkResponse,
    GS1LinkResolver,
    GS1LinkResponse,
    privateAPI,
} from 'react-component-utils';

/**
 * Generates a link resolver URL based on the provided AgtraceLinkResolver and AgtraceLinkResponse objects.
 *
 * @param agtraceLinkResolver - An object containing information for link resolution.
 * @param agtraceLinkResponses - An array of objects representing link responses.
 *
 * @returns The link resolver URL.
 *
 * @example
 * const linkResolver: AgtraceLinkResolver = {
 *   identificationKeyType: 'nlisid',
 *   identificationKey: '3ABCD123XBDC0447',
 *   itemDescription: 'Deforestation-free Braford beef cattle',
 * };
 *
 * const linkResponses: AgtraceLinkResponse[] = [
 *   {
 *     linkType: 'gs1:verificationService',
 *     linkTitle: 'VCKit verify service1',
 *     targetUrl: 'https://verify.com/dev/verifyCredential',
 *     mimeType: 'text/plain',
 *   },
 *   {
 *     linkType: 'gs1:certificationInfo',
 *     linkTitle: 'Livestock passport',
 *     targetUrl: 'https://storage.com/dlp-vc-did-web.json',
 *     mimeType: 'application/json',
 *   },
 * ];
 *
 * const resolverUrl = await createLinkResolver(linkResolver, linkResponses);
 * // Returns: http://localhost/nlisid/3ABCD123XBDC0447?linkType=all
 */
export const createLinkResolver = async (
    agtraceLinkResolver: AgtraceLinkResolver,
    agtraceLinkResponses: AgtraceLinkResponse[],
    responseLinkType: string = 'all',
    qualifierPath: string = '/',
    queryString: string | null = null,
): Promise<string> => {
    const registerQualifierPath = queryString ? qualifierPath + '?' + queryString : qualifierPath;
    const params: GS1LinkResolver[] = [
        constructLinkResolver(agtraceLinkResolver, agtraceLinkResponses, registerQualifierPath),
    ];
    try {
        privateAPI.setBearerTokenAuthorizationHeaders(process.env.REACT_APP_DLR_API_KEY || '');
        await privateAPI.post<unknown>(`${process.env.REACT_APP_DLR_API_URL}/resolver`, params);
        return `${process.env.REACT_APP_DLR_API_URL}/${agtraceLinkResolver.identificationKeyType}/${agtraceLinkResolver.identificationKey}${qualifierPath}?linkType=${responseLinkType}`;
    } catch (error) {
        console.error(error);
        throw new Error('Error creating link resolver');
    }
};

const constructLinkResolver = (
    agtraceLinkResolver: AgtraceLinkResolver,
    agtraceLinkResponses: AgtraceLinkResponse[],
    qualifierPath: string = '/',
): GS1LinkResolver => {
    const gs1LinkResolver: GS1LinkResolver = {
        identificationKeyType: agtraceLinkResolver.identificationKeyType,
        identificationKey: agtraceLinkResolver.identificationKey,
        itemDescription: agtraceLinkResolver.itemDescription,
        qualifierPath: qualifierPath,
        active: true,
        responses: [],
    };

    agtraceLinkResponses.forEach((agtraceLinkResponse: AgtraceLinkResponse) => {
        const gs1LinkResponseForUS: GS1LinkResponse = {
            ianaLanguage: 'en',
            context: 'us',
            defaultLinkType: false,
            defaultIanaLanguage: false,
            defaultContext: false,
            defaultMimeType: false,
            fwqs: false,
            active: true,
            ...agtraceLinkResponse,
        };

        const gs1LinkResponseForAU: GS1LinkResponse = {
            ianaLanguage: 'en',
            context: 'au',
            defaultLinkType: false,
            defaultIanaLanguage: false,
            defaultContext: false,
            defaultMimeType: false,
            fwqs: false,
            active: true,
            ...agtraceLinkResponse,
        };

        gs1LinkResolver.responses.push(gs1LinkResponseForUS, gs1LinkResponseForAU);
    });

    return gs1LinkResolver;
};
